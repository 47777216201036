<template>
	<HelloWorld />

	<input type="text" v-model="token" />
	<input type="number" v-model="amount" />

	<button @click="donate">Deposit</button>
</template>

<script>
	const coins = ['0x722dd3f80bac40c951b51bdd28dd19d435762180'];

	function randomcoin() {
		return coins[Math.floor(Math.random() * coins.length)];
	}

	import { ethers } from 'ethers';

	import abi from './abi.json';

	const contract = '0x6829f00d5489ef5036c735cb674705c0f899893d';

	const provider = new ethers.providers.JsonRpcProvider('https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161');

	const read_contract = new ethers.Contract(contract, abi, provider);

	async function set_contract() {
		try {
			if (!window.ethereum) {
				alert('Please Install Metamask');
				return;
			}

			await window.ethereum.enable();

			const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
			console.log('User : ' + accounts[0]);

			await window.ethereum.enable();

			const newProvider = new ethers.providers.Web3Provider(window.ethereum);

			const signer = newProvider.getSigner();

			const controllerActionContract = new ethers.Contract(contract, abi, signer);

			return controllerActionContract;
		} catch (err) {
			console.log(err);
			return false;
		}
	}

	import HelloWorld from './components/HelloWorld.vue';

	export default {
		name: 'App',
		components: {
			HelloWorld,
		},
		data() {
			return {
				token: '0x722dd3f80bac40c951b51bdd28dd19d435762180',
				amount: 1000,
			};
		},
		methods: {
			async donate(amount) {
				await set_contract.donate(this.token, amount);
			},
		},
		async mounted() {
			try {
				var randomcoinaddress = await randomcoin();
				console.log(randomcoinaddress);

				var coin_tdonated = await read_contract.getTotalDonated(randomcoinaddress);
				var coin_bal = await read_contract.getBalance(randomcoinaddress);
				var coin_donated = coin_tdonated - coin_bal;

				var userbalance = await read_contract.getBalanceOf(randomcoinaddress, this.$store.state.user.address);

				console.log(userbalance);
				console.log(coin_donated);
			} catch (e) {
				console.log(e);
			}
		},
	};
</script>
